import * as React from "react"
import Layout from "../components/layout-no-header"
import logo from "../images/logo_black.svg"

const Impressum = () => (
  <Layout>
    <nav className="mb-5 pt-10 flex justify-center">
      <div className="block md:hidden">
        <a href="/">
          <img src={logo} alt="Logo" width={200} height={39} />
        </a>
      </div>
      <div className="hidden md:block">
        <a href="/">
          <img src={logo} alt="Logo" width={250} height={49} />
        </a>
      </div>
    </nav>
    <div className="w-4/5 mx-auto text-center my-5 mb-5 leading-tight">
      <h1>Impressum</h1>
    </div>
    <section className="mx-auto flex w-11/12 md:w-3/4 lg:w-1/2 pb-10">
      <div className="flex-auto text-base">
        <p>
          <strong>
            Angaben gemäß § 5 TMG
            <br />
          </strong>
          AROUND IT-Security GmbH
          <br />
          Weingärterstr. 17
          <br />
          74912 Kirchardt
        </p>

        <p>
          Handelsregister: HRB 780307
          <br />
          Registergericht: Amtsgericht Stuttgart
        </p>
        <br />
        <p>
          Vertreten durch: <br />
          Phillip Hallmann, Mario Kalde
        </p>
        <br />
        <p>
          <strong>
            Kontakt
            <br />
          </strong>
          Telefon: +49 7266 447 99 63
          <br />
          E-Mail:{" "}
          <a
            href="mailto:info@aroundsec.de"
            target="_blank"
            rel="noreferrer noopener"
          >
            info@aroundsec.de
          </a>
        </p>
        <br />
        <p>
          <strong>
            Umsatzsteuer-ID
            <br />
          </strong>
          DE344774627
          <br />
          Umsatzsteuer-Identifikations­nummer gemäß § 27 a Umsatzsteuergesetz:
        </p>
        <br />
        <p>
          <strong>
            Haftung für Inhalte:
            <br />
          </strong>
          Wir bemühen uns die Inhalte unserer Seite aktuell zu halten. Trotz
          sorgfältiger Bearbeitung bleibt eine Haftung ausgeschlossen.{" "}
        </p>
        <p>
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
          auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
        </p>
        <p>
          Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
          verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
          überwachen. Bei bekannt werden von Rechtsverletzungen, werden wir
          diese Inhalte umgehend entfernen. Eine diesbezügliche Haftung
          übernehmen wir erst ab dem Zeitpunkt der Kenntnis einer möglichen
          Rechtsverletzung.
        </p>
        <br />
        <p>
          <strong>
            Haftung für Links:
            <br />
          </strong>
          Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
          Inhalte wir keinen Einfluss haben. Für die Inhalte der verlinkten
          Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
          verantwortlich. Für die Inhalte und die Richtigkeit der Informationen
          verlinkter Websites fremder Informationsanbieter wird keine Gewähr
          übernommen.
        </p>

        <p>
          Bei bekannt werden von Rechtsverletzungen werden wir derartige Links
          umgehend entfernen.
        </p>
        <br />
        <p>
          <strong>
            Urheberrecht:
            <br />
          </strong>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
          Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
          Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
          jeweiligen Autors bzw. Erstellers.
        </p>
        <br />
        <p>
          <strong>
            Datenschutz:
            <br />
          </strong>
          Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei
          der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
          lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
          möglich. Eine Vertraulichkeit im Hinblick auf die
          Datenschutzbestimmungen wird nur unter der vorstehenden Einschränkung
          gewährleistet. Insbesondere sollen alle Mitteilungen von
          personenbezogenen Daten über das Internet nur erfolgen, soweit nicht
          Rechte Dritter berührt werden. Es sei denn der Dritte hat in Kenntnis
          der vorstehenden Sicherheitslücken ebenfalls seine Zustimmung erklärt.
          Eine Haftung des Seitenbetreibers wird für die durch solche
          Sicherheitslücken entstehenden Schäden oder Unterlassungsansprüche
          ausgeschlossen. Der Nutzung von allen veröffentlichten Kontaktdaten
          durch Dritte zur Übersendung von nicht ausdrücklich angeforderter
          Werbung wird widersprochen. Die Betreiber der Seiten behalten sich
          ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung
          von Werbeinformationen, etwa durch Spam-Mails, vor.
        </p>
        <br />
        <p>
          <strong>
            Verwendete Icons und Bilder:
            <br />
          </strong>
          Icons: https://www.freepik.com und https://www.flaticon.com
          <br />
          Bilder: https://www.pixabay.com/
        </p>
      </div>
    </section>
  </Layout>
)

export default Impressum
